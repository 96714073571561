<template>
  <div class="flex flex-column">
    <b v-if="showTitle" class="my-2"> {{ title }} </b>
    <div class="w-full flex trn-calendar justify-content-start">
      <Calendar
          class="w-16rem"
          selectOtherMonths="true"
          v-model="timeTrnValue"
          @date-select="setDateTrn"
          @clear-click="setClearDate"
          selectionMode="range"
          dateFormat="dd-mm-yy"
          :showButtonBar="false"
          :showIcon="true"
          :showOnFocus="true"
          ref="calendar"
      ></Calendar>

      <Button
          @click="setDatePro('today')"
          class="w-6rem ml-2 justify-content-center p-button-text p-0 underline"
      >
        Сегодня
      </Button>
      <Button
          @click="setDatePro('tomorrow')"
          class="w-6rem ml-2 justify-content-center p-button-text p-0 underline"
      >
        Вчера
      </Button>
      <Button
          @click="setDatePro('curMounth')"
          class="w-8rem ml-2 justify-content-center p-button-text p-0 underline"
      >
        Текущий месяц
      </Button>
      <Button
          @click="setDatePro('lastMounth')"
          class="w-10rem ml-2 justify-content-center p-button-text p-0 underline"
      >
        Прошлый месяц
      </Button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    timeArray: {
      type: Array,
      required: true
    },
    showTitle: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    firstRender: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dateStartTrn: null,
      dateEndTrn: null,
      timeTrnValue: this.timeArray,
    };
  },
  components: {},
  methods: {
    getDate(date, type) {
      let start = "T00:00:00";
      let end = "T23:59:59";
      return type === "start" ? date + start : date + end;
    },
    setDateTrn() {
      if (this.timeTrnValue !== null && this.timeTrnValue) {
        this.dateStartTrn = this.getDate(
            new Date(this.timeTrnValue[0] - this.timeTrnValue[0].getTimezoneOffset() * 60000)
                .toISOString()
                .split("T")[0],
            "start"
        );
        if (this.timeTrnValue[1]) {
          this.dateEndTrn = this.getDate(
              new Date(this.timeTrnValue[1] - this.timeTrnValue[0].getTimezoneOffset() * 60000)
                  .toISOString()
                  .split("T")[0],
              "end"
          );
        } else {
          this.dateEndTrn = this.getDate(
              new Date(this.timeTrnValue[0] - this.timeTrnValue[0].getTimezoneOffset() * 60000)
                  .toISOString()
                  .split("T")[0],
              "end"
          );
        }
      }
      console.log(this.dateEndTrn)
      console.log(this.dateStartTrn)
    },
    setClearDate() {
      this.dateStartTrn = null;
      this.dateEndTrn = null;
    },
    setDatePro(type) {
      this.timeTrnValue = [];
      let today = new Date();
      let currentYear = today.getFullYear();
      let currentMonth = today.getMonth();
      let days = new Date(currentYear, currentMonth, 0).getDate();
      let firstDayCurrYear, lastDayCurrYear;
      if (type === "tomorrow") {
        today.setDate(today.getDate() - 1);
        let tomorrow = today.toISOString().split("T")[0];
        this.dateStartTrn = this.getDate(tomorrow, "start");
        this.dateEndTrn = this.getDate(tomorrow, "end");
        this.timeTrnValue.push(today);
      } else if (type === "curMounth") {
        firstDayCurrYear = new Date(currentYear, currentMonth, 1);
        this.timeTrnValue[0] = firstDayCurrYear;
        this.timeTrnValue[1] = today;
        this.dateStartTrn = this.getDate(
            new Date(
                this.timeTrnValue[0] - this.timeTrnValue[0].getTimezoneOffset() * 60000
            )
                .toISOString()
                .split("T")[0],
            "start"
        );

        this.dateEndTrn = this.getDate(
            today.toISOString().split("T")[0],
            "end"
        );
      } else if (type === "lastMounth") {
        firstDayCurrYear = new Date(currentYear, currentMonth - 1, 1);
        lastDayCurrYear = new Date(currentYear, currentMonth - 1, days);
        this.timeTrnValue[0] = firstDayCurrYear;
        this.timeTrnValue[1] = lastDayCurrYear;
        this.dateStartTrn = this.getDate(
            new Date(
                this.timeTrnValue[0] - this.timeTrnValue[0].getTimezoneOffset() * 60000
            )
                .toISOString()
                .split("T")[0],
            "start"
        );
        this.dateEndTrn = this.getDate(
            new Date(
                this.timeTrnValue[1] - this.timeTrnValue[0].getTimezoneOffset() * 60000
            )
                .toISOString()
                .split("T")[0],
            "end"
        );
      } else if (type === "today") {
        this.timeTrnValue = [];
        this.timeTrnValue[0] = new Date();
        // this.timeTrnValue[1] = null;

        this.dateEndTrn = this.getDate(
            new Date().toISOString().split("T")[0],
            "end"
        );
        this.setDateTrn();
      }
    },
    getDateToParent() {
      this.$emit('getDate', {dateStart: this.dateStartTrn, dateEnd: this.dateEndTrn, timeArray: this.timeTrnValue})
    }

  },
  watch: {
    timeTrnValue() {
      this.getDateToParent()
    },
    timeArray() {
      this.timeTrnValue = this.timeArray
      this.setDateTrn()
      this.getDateToParent()
    }
  },
  mounted() {
    this.$primevue.config.locale.today = "Сегодня";
    this.$primevue.config.locale.clear = "Очистить";
    this.$primevue.config.locale.dayNames = [
      "Воскресенье",
      "Понедельнк",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ];
    this.$primevue.config.locale.dayNamesShort = [
      "Вос",
      "Пон",
      "Вто",
      "Сре",
      "Чет",
      "Пят",
      "Суб",
    ];
    this.$primevue.config.locale.dayNamesMin = [
      "Вс",
      "Пн",
      "Вт",
      "Ср",
      "Чт",
      "Пт",
      "Сб",
    ];
    this.$primevue.config.locale.monthNames = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ];
    this.$primevue.config.locale.monthNamesShort = [
      "Янв",
      "Фев",
      "Мар",
      "Апр",
      "Май",
      "Июн",
      "Июл",
      "Авг",
      "Сент",
      "Окт",
      "Ноя",
      "Дек",
    ];
    this.$primevue.config.locale.firstDayOfWeek = 1;
    if (this.firstRender) {
      this.setDatePro("curMounth")
      this.getDateToParent()
    }
  },
  created() {
    if (this.firstRender) {
      this.setDatePro("curMounth")
      this.getDateToParent()
    }
!this.timeTrnValue.length ? this.timeTrnValue.push(new Date()) : [];
}
,
}
;
</script>

<style lang="scss">
.p-highlight {
  color: #495057 !important;
}
</style>