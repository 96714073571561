import api from "@/api/index";

import {
  timeFormater
} from "@/handlers/timeFormater";
import {
  setEmoji
} from "@/handlers/emoji";
import store from "@/store";

export function getTrnReport(that) {
  function isTotal(type) {
    if (that.totals && type !== "1c" && type !== 'trn_page_report') {
      return "total_" + type;
    } else {
      return type;
    }
  }

  function whatThis() {
    if (that.oneass) {
      return "1c";
    } else if (that.alltrn) {
      return 'trn_page_report'
    } else {
      return that.selectTypeReport[0] ?
        isTotal(that.selectTypeReport[0]) :
        isTotal("trn_report");
    }
  }
  api
    .get("report/get_trns", {
      responseType: "blob",
      params: {
        dateStart: that.dateCalendar.start,
        dateEnd: that.dateCalendar.end,
        file_type: that.selectFormatReport,
        company: that.companyReport ?
          that.companyReport : store.state.company.curCompany.id,
        to: that.to ? "" : null,
        group_to: that.group_to ? "" : null,
        address: that.address ? "" : null,
        split: that.spliting ? "" : null,
        report_type: whatThis(),
        card_type: that.selectCardType,
        service_name: that.service ? "" : null,
        base_code: that.baseCodeSelect,
        cards: that.selectedCards
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      const href = URL.createObjectURL(r.data);
      const format = !that.spliting ? that.selectFormatReport : "zip";
      const nameFile =
        "Оборот по обслуживанию " +
        timeFormater(that.dateCalendar.start) +
        " - " +
        timeFormater(that.dateCalendar.end) +
        "." +
        format;
      // that.downloadLinks.show = true
      // that.downloadLinks.href = href
      // that.downloadLinks.downloadName = nameFile
      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", nameFile); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((err) => {
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "За данный период времени, кол-во транзакций превышает 15000 шт. Уменьшите период времени",
            life: 5000,
          });
          break;
        case 423:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Следующий отчет можно будет сформировать через 10 секунд",
            life: 5000,
          });
          break;
        case 412:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Данные отсутсвуют. Поменяйте параметры запроса",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}


export function getSuperTrnReport(that) {
  api
    .get("report/get_trns", {
      responseType: "blob",
      params: {
        dateStart: that.dateCalendar.start,
        dateEnd: that.dateCalendar.end,
        file_type: that.selectFormatReport,
        company: that.companyReport ?
          that.companyReport : store.state.company.curCompany.id,
        report_type: 'trn_page_report',
        service_name: '',
        to_group: that.selectColumnTrn.includes("group_to") ? "" : null,
        address: that.selectColumnTrn.includes("address") ? "" : null,
        num_contract: that.selectColumnTrn.includes("contract_num") ? '' : null,
        code_to: that.selectColumnTrn.includes("to_code") ? '' : null,
        date_contract: that.selectColumnTrn.includes("contract_date") ? '' : null,
        holder_name: that.selectColumnTrn.includes("holder") ? '' : null,
        date_field: that.selectColumnTrn.includes("date") ? '' : null,
        group_card: that.selectColumnTrn.includes("card_group") ? '' : null,
        handler: that.selectColumnTrn.includes("handler") ? '' : null,
        amount: that.selectColumnTrn.includes("amount") ? '' : null,
        price: that.selectColumnTrn.includes("price") ? '' : null,
        price_to: that.selectColumnTrn.includes("price_to") ? '' : null,
        sum: that.selectColumnTrn.includes("sum") ? '' : null,
        sum_to: that.selectColumnTrn.includes("sum_to") ? '' : null,
        card_number: that.selectColumnTrn.includes("card_number") ? '' : null,
        companies: '',
        cards: that.selectedCards
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      const href = URL.createObjectURL(r.data);
      const format = !that.spliting ? that.selectFormatReport : "zip";
      const nameFile =
        "Оборот по обслуживанию " +
        timeFormater(that.dateCalendar.start) +
        " - " +
        timeFormater(that.dateCalendar.end) +
        "." +
        format;
      // that.downloadLinks.show = true
      // that.downloadLinks.href = href
      // that.downloadLinks.downloadName = nameFile
      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", nameFile); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((err) => {
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "За данный период времени, кол-во транзакций превышает 15000 шт. Уменьшите период времени",
            life: 5000,
          });
          break;
        case 423:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Следующий отчет можно будет сформировать через 10 секунд",
            life: 5000,
          });
          break;
        case 412:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Данные отсутсвуют. Поменяйте параметры запроса",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}

export function getCardsMini(that, callback) {
  /*Получение и обработка списка карт*/
  api
    .get("/cards/list", {
      params: {
        company: that.companyReport ?
          that.companyReport : store.state.company.curCompany.id,
        rows: 100000,
        mini: true
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.optionCardsList = r.data
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка получения списка карт  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
}

export function getReportsTools(that) {
  api
    .get("/main/get_fields", {
      params: {
        company: that.companyReport ?
          that.companyReport : [store.state.company.curCompany.id],
        base_code: '',
        card_type: ''
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.baseCode = r.data.base;
      that.cardType = r.data.card_type
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка получения списка карт  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    }).finally(() => {
      that.loading = false;
    })
}