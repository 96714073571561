<template>
  <layout-base>
    <loading-progress :loading="loading" />
    <MobileSpiner :loading="loading" />
    <!-- <div v-if="fullCompanyList.length" class="mt-1 report-wrapper"> -->
    <div class="mt-1 report-wrapper">
      <Card>
        <template #content>
          <TabView
            @tab-change="tabChange($event)"
            lazy
            v-model:activeIndex="active"
          >
            <TabPanel header="Общие отчеты">
              <div class="flex mt-2 flex-column grid w-full ml-0">
                <!-- <b class="my-2"> Шаблон отчета: </b>
                <MultiSelect
                  class="w-4"
                  v-model="selectTemplateReport"
                  :options="templateReport"
                  optionLabel="name"
                  placeholder="Выбрать шаблон"
                  :selectionLimit="2"
                  showToggleAll="false"
                  optionValue="value"
                  @change="toggleValue($event)"
                /> -->
                <div class="col-12 md:col-5 flex flex-column p-0">
                  <b class="my-2"> Группировать: </b>
                  <MultiSelect
                    :disabled="oneass || loading"
                    v-model="selectTypeReport"
                    :options="typeReport"
                    optionLabel="name"
                    placeholder="Выбрать группировку"
                    :selectionLimit="2"
                    showToggleAll="false"
                    optionValue="value"
                    ref="selectGrouping"
                    @change="toggleValueType($event)"
                  >
                    <template #footer>
                      <div class="p-multiselect-footer bg-gray-300">
                        <div
                          class="flex align-items-center justify-content-end"
                          style="padding: 0.75rem 1.25rem"
                        >
                          <Button
                            @click="this.$refs.selectGrouping.hide()"
                            class="p-button-sm"
                            label="Закрыть"
                          />
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                </div>
                <div class="col-12 md:col-5 p-0">
                  <SelectCompany
                    :disabled="loading"
                    class="mt-2 mb-4"
                    :selected-items="selectedCompany"
                    :items="companies"
                    :showTitle="true"
                    title="Компании"
                    :upadter="loading"
                    @hide="getReportsToolsGlobal"
                    v-on:upToParentItems="updateCompany"
                  />
                </div>
                <!-- <div v-if="$store.state.curUser">
                  <HandlerSelect
                    v-if="$store.state.curUser.role !== 2"
                    :handlers="handlers"
                    class="w-4 my-2"
                    :showTitle="true"
                    title="Обработчик"
                    @setHandler="setHandlersInReports"
                    :placeholder="'Выберите обработчик'"
                    :disabled="!selectedCompany.length"
                    v-tooltip="
                      !selectedCompany.length
                        ? 'Выберите сперва компанию'
                        : null
                    "
                  />
                </div> -->
                <b class="my-2"> Период: </b>
                <TheCalendarFromTo v-on:setDate="getDateFromComponent" />
                <!-- <div v-if="$store.state.curUser"> -->
                <!-- v-if="$store.state.curUser.role == 2" -->
                <div class="mt-4">
                  <Inplace>
                    <template #display>
                      <Button
                        label="Дополнительные фильтры"
                        icon="pi pi-cog"
                        iconPos="right"
                      />
                    </template>
                    <template #content>
                      <div class="grid flex-column mt-2 ml-0">
                        <div class="col-12 md:col-5 flex flex-column p-0">
                          <b class="my-2"> По номеру карты: </b>
                          <MultiSelect
                            :disabled="oneass || loading"
                            v-model="selectedCards"
                            :options="optionCardsList"
                            optionLabel="number"
                            placeholder="Выбрать карту:"
                            dataKey="number"
                            optionValue="number"
                            :showToggleAll="false"
                            ref="selectCardList"
                            filter
                          >
                            <template #footer>
                              <div class="p-multiselect-footer bg-gray-300">
                                <div
                                  class="flex align-items-center justify-content-between"
                                  style="padding: 0.75rem 1.25rem"
                                >
                                  <Button
                                    @click="selectedCards = null"
                                    class="p-button-sm p-button-text underline p-0 flex justify-content-center"
                                    label="Сбросить выбранные карты"
                                  />
                                  <Button
                                    @click="this.$refs.selectCardList.hide()"
                                    class="p-button-sm"
                                    label="Закрыть"
                                  />
                                </div>
                              </div>
                            </template>
                          </MultiSelect>
                          <small class="my-2"
                            >Если не выбрано ни одной карты, отчет будет
                            сформирован по всем картам</small
                          >
                        </div>
                        <div class="flex flex-column mt-2 col-12 md:col-5 p-0">
                          <b class="my-2">По типу карты: </b>
                          <MultiSelect
                            :disabled="loading"
                            v-model="selectCardType"
                            :options="cardType"
                            optionLabel="card_type__code__name"
                            placeholder="Выбрать тип карты:"
                            :selectionLimit="10"
                            showToggleAll="false"
                            ref="selectCardsList"
                            filter
                            :emptyMessage="'Нет данных'"
                            optionValue="card_type__code__code"
                          >
                            <template #footer>
                              <div class="p-multiselect-footer bg-gray-300">
                                <div
                                  class="flex align-items-center justify-content-end"
                                  style="padding: 0.75rem 1.25rem"
                                >
                                  <Button
                                    @click="this.$refs.selectCardType.hide()"
                                    class="p-button-sm"
                                    label="Закрыть"
                                  />
                                </div>
                              </div>
                            </template>
                          </MultiSelect>
                          <!-- </div> -->
                        </div>
                        <div
                          v-if="this.$store.state?.curUser?.role == 1"
                          class="flex flex-column mt-2 col-12 md:col-5 p-0"
                        >
                          <b class="my-2">По коду базы: </b>
                          <MultiSelect
                            :disabled="loading"
                            v-model="baseCodeSelect"
                            :options="baseCode"
                            optionLabel="name"
                            optionValue="code"
                            placeholder="Выбрать базу:"
                            :selectionLimit="10"
                            :emptyMessage="'Нет данных'"
                            ref="selectBaseCode"
                          >
                            <template #footer>
                              <div class="p-multiselect-footer bg-gray-300">
                                <div
                                  class="flex align-items-center justify-content-end"
                                  style="padding: 0.75rem 1.25rem"
                                >
                                  <Button
                                    @click="this.$refs.selectBaseCode.hide()"
                                    class="p-button-sm"
                                    label="Закрыть"
                                  />
                                </div>
                              </div>
                            </template>
                          </MultiSelect>
                          <!-- </div> -->
                        </div>
                      </div>
                    </template>
                  </Inplace>
                </div>
                <div class="flex flex-column mt-2 col-12 md:col-5 p-0">
                  <b class="my-2"> Добавить столбцы: </b>
                  <MultiSelect
                    :disabled="oneass"
                    v-model="selectColumn"
                    :options="
                      !$store.state.curUser.hide_price ? columns : columnsTO
                    "
                    optionLabel="name"
                    placeholder="Выбрать столбец:"
                    :selectionLimit="10"
                    showToggleAll="false"
                    optionValue="value"
                    ref="selectColumn"
                    @change="toggleValueColumn($event)"
                  >
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.name }}</span>
                      </div>
                    </template>
                    <template #footer>
                      <div class="p-multiselect-footer bg-gray-300">
                        <div
                          class="flex align-items-center justify-content-end"
                          style="padding: 0.75rem 1.25rem"
                        >
                          <Button
                            @click="this.$refs.selectColumn.hide()"
                            class="p-button-sm"
                            label="Закрыть"
                          />
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <!-- </div> -->
                </div>
                <div class="mt-4 flex align-items-center w-full flex-wrap">
                  <div class="flex align-items-center mt-2 w-full lg:w-auto">
                    <Checkbox
                      :disabled="oneass"
                      id="spliter"
                      v-model="spliting"
                      :binary="true"
                    ></Checkbox>
                    <span class="ml-2 font-bold">
                      Разбивка отчета по каждой компании</span
                    >
                  </div>
                  <div
                    class="flex align-items-center mt-2 w-full lg:w-auto ml-0 lg:ml-4"
                  >
                    <Checkbox
                      :disabled="oneass"
                      id="totals"
                      v-model="totals"
                      :binary="true"
                    ></Checkbox>
                    <span class="ml-2 font-bold"> Показать только итоги</span>
                  </div>
                  <div
                    class="flex align-items-center mt-2 w-full lg:w-auto ml-0 lg:ml-4"
                  >
                    <Checkbox
                      :disabled="oneass"
                      id="service"
                      v-model="service"
                      :binary="true"
                    ></Checkbox>
                    <span class="ml-2 font-bold">
                      Детализация итогов по услугам</span
                    >
                  </div>
                  <!-- <div
                    v-if="$store.state.curUser.role == 1"
                    class="flex align-items-center mt-2 ml-4"
                  >
                    <Checkbox
                      id="oneass"
                      v-model="oneass"
                      :binary="true"
                    ></Checkbox>
                    <span class="ml-2 font-bold"> Выгрузка в 1С</span>
                  </div> -->
                </div>
              </div>
              <div class="mt-4">
                <b class="my-2"> Формат: </b>
                <SelectButton
                  class="mt-2"
                  v-model="selectFormatReport"
                  :options="formatReport"
                  optionLabel="name"
                  optionValue="value"
                >
                  <template #option="slotProps">
                    <div :class="slotProps.option.class">
                      <div class="font-bold flex align-items-center">
                        {{ slotProps.option.name }}
                        <i
                          class="pi font-bold ml-2"
                          :class="slotProps.option.icon"
                        ></i>
                      </div>
                    </div>
                  </template>
                </SelectButton>
              </div>
              <Button
                @click="postReport"
                :loading="loading"
                icon="pi pi-upload"
                icon-pos="right"
                class="mt-6"
                label="Сформировать"
              />

              <!-- <div class="mt-4" v-if="downloadLinks.show">
                             <a :href='downloadLinks.href' :download="downloadLinks.downloadName">Скачать отчет</a>
                           </div>
                           <div v-if="downloadLinks.show" class="mt-4">
                             <Button type="button"
                                     class="p-button-outlined p-button-help btn-report">

                               <a :href='downloadLinks.href' :download="downloadLinks.downloadName" class="ml-2 font-bold">
                                 <i class="pi pi-file-excel mr-3"> </i>Скчать
                                 отчет</a>
                             </Button>
                           </div> -->
            </TabPanel>
            <TabPanel header="Транзакционные отчеты">
              <div class="flex mt-2 flex-column">
                <div class="grid ml-0">
                  <SelectCompany
                    class="col-12 md:col-5 p-0 mt-2 mb-4"
                    :selected-items="selectedCompany"
                    :items="companies"
                    :showTitle="true"
                    title="Компании"
                    ref="comp"
                    @hide="getReportsToolsGlobal"
                    v-on:upToParentItems="updateCompany"
                  />
                </div>
                <b class="my-2"> Период: </b>
                <TheCalendarFromTo v-on:setDate="getDateFromComponent" />

                <div class="grid flex-column mt-2 ml-0">
                  <div class="col-12 md:col-5 flex flex-column p-0">
                    <b class="my-2"> По номеру карты: </b>
                    <MultiSelect
                      :disabled="oneass || loading"
                      v-model="selectedCards"
                      :options="optionCardsList"
                      optionLabel="number"
                      placeholder="Выбрать карту:"
                      dataKey="number"
                      optionValue="number"
                      :showToggleAll="false"
                      ref="selectCardList"
                      filter
                    >
                      <template #footer>
                        <div class="p-multiselect-footer bg-gray-300">
                          <div
                            class="flex align-items-center justify-content-between"
                            style="padding: 0.75rem 1.25rem"
                          >
                            <Button
                              @click="selectedCards = null"
                              class="p-button-sm p-button-text underline p-0 flex justify-content-center"
                              label="Сбросить выбранные карты"
                            />
                            <Button
                              @click="this.$refs.selectCardList.hide()"
                              class="p-button-sm"
                              label="Закрыть"
                            />
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <small class="my-2"
                      >Если не выбрано ни одной карты, отчет будет сформирован
                      по всем картам</small
                    >
                  </div>
                  <div class="flex flex-column mt-2 col-12 md:col-5 p-0">
                    <b class="my-2"> Выбранные столбцы: </b>
                    <MultiSelect
                      :disabled="oneass"
                      v-model="selectColumnTrn"
                      :options="
                        !$store.state.curUser.hide_price
                          ? columnsTrn
                          : columnsTrnTO
                      "
                      filter
                      optionLabel="name"
                      placeholder="Добавить столбец:"
                      showToggleAll="false"
                      optionValue="value"
                      ref="selectColumn"
                      @change="toggleValueColumnTrn($event)"
                    >
                      <!-- <template #option="slotProps">
                        <div>
                          <span>{{ slotProps.option.name }}</span>
                        </div>
                      </template> -->
                      <template #footer>
                        <div class="p-multiselect-footer bg-gray-300">
                          <div
                            class="flex align-items-center justify-content-end"
                            style="padding: 0.75rem 1.25rem"
                          >
                            <Button
                              @click="this.$refs.selectColumn.hide()"
                              class="p-button-sm"
                              label="Закрыть"
                            />
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                  </div>
                </div>
              </div>
              <Button
                @click="postReport"
                :loading="loading"
                icon="pi pi-upload"
                icon-pos="right"
                class="mt-6"
                label="Сформировать"
              />
            </TabPanel>
            <TabPanel v-if="$store.state.curUser?.role == 1" header="1C">
              <div class="flex mt-2 flex-column">
                <div class="grid ml-0">
                  <SelectCompany
                    class="col-12 md:col-5 p-0 mt-2 mb-4"
                    :selected-items="selectedCompany"
                    :items="companies"
                    :showTitle="true"
                    title="Компании"
                    v-on:upToParentItems="updateCompany"
                    @hide="getReportsToolsGlobal"
                  />
                </div>
                <b class="my-2"> Период: </b>
                <TheCalendarFromTo v-on:setDate="getDateFromComponent" />

                <!-- <div v-if="$store.state.curUser"> -->
                <!-- v-if="$store.state.curUser.role == 2" -->
                <div class="grid flex-column mt-2 ml-0">
                  <div class="flex flex-column mt-2 col-12 md:col-5 p-0">
                    <b class="my-2"> Тип карты: </b>
                    <MultiSelect
                      :disabled="loading"
                      v-model="selectCardType"
                      :options="cardType"
                      optionLabel="card_type__code__name"
                      placeholder="Выбрать тип карты:"
                      :selectionLimit="10"
                      showToggleAll="false"
                      ref="selectCardType"
                      :emptyMessage="'Нет данных'"
                      optionValue="card_type__code__code"
                    >
                      <template #footer>
                        <div class="p-multiselect-footer bg-gray-300">
                          <div
                            class="flex align-items-center justify-content-end"
                            style="padding: 0.75rem 1.25rem"
                          >
                            <Button
                              @click="this.$refs.selectCardType.hide()"
                              class="p-button-sm"
                              label="Закрыть"
                            />
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <!-- </div> -->
                  </div>
                  <div class="flex flex-column mt-2 col-12 md:col-5 p-0">
                    <b class="my-2"> Фильтр по коду базы: </b>
                    <MultiSelect
                      :disabled="loading"
                      v-model="baseCodeSelect"
                      :options="baseCode"
                      optionLabel="name"
                      optionValue="code"
                      placeholder="Выбрать базу:"
                      :selectionLimit="10"
                      :emptyMessage="'Нет данных'"
                      ref="selectBaseCode"
                    >
                      <template #footer>
                        <div class="p-multiselect-footer bg-gray-300">
                          <div
                            class="flex align-items-center justify-content-end"
                            style="padding: 0.75rem 1.25rem"
                          >
                            <Button
                              @click="this.$refs.selectBaseCode.hide()"
                              class="p-button-sm"
                              label="Закрыть"
                            />
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
              <Button
                @click="postReport"
                :loading="loading"
                icon="pi pi-upload"
                icon-pos="right"
                class="mt-6"
                label="Сформировать"
              />
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </div>
  </layout-base>
</template>
<script>
import MultiCalendar from "@/components/MultiCalendar";
import SelectCompany from "@/components/SelectCompany";
import HandlerSelect from "@/components/Reports/HandlerSelect";

import store from "@/store";

import {
  getTrnReport,
  getSuperTrnReport,
  getCardsMini,
  getReportsTools,
} from "@/api/report";
// import { getCompany } from "@/api/company";
import TheCalendarFromTo from "../components/TheCalendarFromTo.vue";

export default {
  data() {
    return {
      active: 0,
      loading: true,
      companies: [],
      selectedCompany: [],
      timeArray: [],
      companyReport: null,
      selectTemplateReport: [false],
      selectTypeReport: ["trn_report"],
      dateCalendar: {
        start: "",
        end: "",
      },
      // templateReport: [
      //   { name: "Транзакционные", value: false },
      //   { name: "Транзакционный итоги", value: true },
      // ],
      typeReport: [
        { name: "По картам", value: "trn_report" },
        { name: "По группе карт", value: "group_card_report" },
        { name: "По держателям карт", value: "holder_report" },
        {
          name: "По держателям в группе карт",
          value: "holder_group_in_group_card_reports",
        },
      ],
      selectFormatReport: "xlsx",
      formatReport: [
        {
          name: "Excel",
          icon: "pi-file-excel",
          class: "text-green-500",
          value: "xlsx",
        },
        // {name: 'PDF', icon: 'pi-file-pdf', class: 'text-red-500', value: 'pdf'},
      ],
      downloadLinks: {
        show: false,
        href: "",
        downloadName: "",
      },
      handlers: null,
      handlerReport: "",
      to: false,
      group_to: false,
      address: false,
      to: false,
      service: true,
      group_to: false,
      price_to: false,
      sum_to: false,
      handler: false,
      card_number: false,
      date: false,
      contract_num: false,
      contract_date: false,
      holder: false,
      card_group: false,
      amount: false,
      price: false,
      sum: false,
      to_code: false,
      spliting: false,
      cardType: [],
      selectCardType: null,
      totals: false,
      selectColumn: null,
      // selectColumnTrn: !store.state?.curUser?.hide_price
      //   ? [
      //       "group_to",
      //       "address",
      //       "contract_num",
      //       "contract_date",
      //       "holder",
      //       "date",
      //       "amount",
      //       "price",
      //       "sum",
      //       "card_number",
      //     ]
      //   : [
      //       "group_to",
      //       "address",
      //       "contract_num",
      //       "contract_date",
      //       "holder",
      //       "date",
      //       "handler",
      //       "amount",
      //       "price",
      //       "price_to",
      //       "sum",
      //       "sum_to",
      //       "card_number",
      //     ],
      selectColumnTrn: [
        "group_to",
        "address",
        "contract_num",
        "contract_date",
        "holder",
        "date",
        "handler",
        "amount",
        "price",
        "price_to",
        "sum",
        "sum_to",
        "card_number",
      ],
      service: true,
      columns: [
        { name: "Цена ТО", value: "to" },
        { name: "Поставщик", value: "group_to" },
        { name: "Адрес", value: "address" },
      ],
      columnsTO: [
        { name: "Поставщик", value: "group_to" },
        { name: "Адрес", value: "address" },
      ],
      columnsTrn: [
        {
          name: "Цена ТО",
          value: "price_to",
        },
        {
          name: "Cумма ТО",
          value: "sum_to",
        },
        {
          name: "Обработчик",
          value: "handler",
        },
        { name: "Поставщик", value: "group_to" },
        { name: "Адрес", value: "address" },
        {
          name: "Номер карты",
          value: "card_number",
        },
        {
          name: "Дата",
          value: "date",
        },
        {
          name: "Номер договора",
          value: "contract_num",
        },
        {
          name: "Дата договора",
          value: "contract_date",
        },
        {
          name: "Держатель",
          value: "holder",
        },
        {
          name: "Группа карт",
          value: "card_group",
        },
        {
          name: "Кол-во",
          value: "amount",
        },
        {
          name: "Цена",
          value: "price",
        },
        {
          name: "Cумма",
          value: "sum",
        },
        {
          name: "Код ТО",
          value: "to_code",
        },
      ],
      columnsTrnTO: [
        { name: "Поставщик", value: "group_to" },
        {
          name: "Адрес",
          value: "address",
        },
        {
          name: "Номер карты",
          value: "card_number",
        },
        {
          name: "Дата",
          value: "date",
        },
        {
          name: "Номер договора",
          value: "contract_num",
        },
        {
          name: "Дата договора",
          value: "contract_date",
        },
        {
          name: "Держатель",
          value: "holder",
        },
        {
          name: "Группа карт",
          value: "card_group",
        },
        {
          name: "Кол-во",
          value: "amount",
        },
        {
          name: "Цена",
          value: "price",
        },
        {
          name: "Cумма",
          value: "sum",
        },
        {
          name: "Код ТО",
          value: "to_code",
        },
      ],
      oneass: false,
      alltrn: false,
      baseCode: [],
      baseCodeSelect: null,
      fullCompanyList: [],
      optionCardsList: null,
      selectedCards: null,
    };
  },
  components: {
    MultiCalendar,
    SelectCompany,
    HandlerSelect,
    TheCalendarFromTo,
  },
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
    // companyList() {
    //   return this.fullCompanyList;
    // },
    companyList() {
      return store.state.company.companyList.map(function (el) {
        return {
          id: el.id,
          name: el.full_name,
        };
      });
    },
  },
  watch: {
    curCompany() {
      this.selectedCompany = [];
      this.getCompanyFromStore();
    },
    selectedCompany(value) {
      this.selectCardType = null;
      this.baseCodeSelect = null;
      let arrType = [];
      let arrBase = [];
      // this.baseCode = [];
      // this.cardType = [];
      // this.fullCompanyList.forEach((el) => {
      //   if (value.length && value[0] != 0) {
      //     value.forEach((item) => {
      //       if (item === el.id) {
      //         arrType.push(el.card_type);
      //         if (el.base_code) {
      //           arrBase.push({
      //             base_code: el.base_code,
      //             base_name: el.base_name,
      //           });
      //         }
      //       }
      //     });
      //   } else {
      //     arrType.push(el.card_type);
      //     if (el.base_code) {
      //       arrBase.push({
      //         base_code: el.base_code,
      //         base_name: el.base_name,
      //       });
      //     }
      //   }
      //   // if (el.id === value[0]) {
      //   //   this.cardType = el.card_type;
      //   //   // console.log(el.card_type); this.cardType.flat(1)
      //   // }
      // });
      // this.cardType = arrType.flat(1).reduce((o, i) => {
      //   if (
      //     !o.find((v) => v.card_type__code__code == i.card_type__code__code)
      //   ) {
      //     o.push(i);
      //   }
      //   return o;
      // }, []);
      // this.baseCode = arrBase.flat(1).reduce((o, i) => {
      //   if (!o.find((v) => v.base_code == i.base_code)) {
      //     o.push(i);
      //   }
      //   return o;
      // }, []);
      // console.log(this.cardType.flat(1));
    },
    oneass(value) {
      if (value) {
        (this.service = false), (this.totals = false), (this.spliting = false);
        this.selectColumn = [];
        this.selectTypeReport = [];
      } else {
        this.selectTypeReport = ["trn_report"];
        this.baseCodeSelect = [];
      }
    },
  },
  methods: {
    tabChange(event) {
      if (event.index === 2) {
        this.oneass = true;
      } else {
        this.oneass = false;
      }
      if (event.index === 1) {
        this.oneass = false;
        this.alltrn = true;
      } else {
        this.alltrn = false;
      }
      this.selectColumn = null;
      this.to = false;
      this.group_to = false;
      this.address = false;
      this.selectedCompany = [];
      this.companyReport = null;
      this.selectedCards = null;
      // getCompany(this);
      this.getCompanyFromStore();
      this.getReportsToolsGlobal();
    },
    getCompanyFromStore() {
      store.state.company.curCompany
        ? this.selectedCompany.push(store.state.company.curCompany.id)
        : null;
      store.state.company.companyList
        ? (this.companies = store.state.company.companyList.map(function (el) {
            return {
              id: el.id,
              name: el.full_name,
            };
          }))
        : null;
    },
    updateCompany(data) {
      console.log(data);
      this.selectedCompany = data.items;
      this.companyReport = this.selectedCompany;
      this.selectedCards = null;
      // this.setHandlers(data);
      // this.setBaseCode(data);
      // this.setCardType(data);
    },
    getDateFromComponent(data) {
      this.dateCalendar.start = data.start;
      this.dateCalendar.end = data.end;
    },
    postReport() {
      this.loading = true;
      // this.$toast.add({
      //   severity: "info",
      //   summary: `Отчет формируется`,
      // });
      if (this.active == 1) {
        getSuperTrnReport(this);
      } else {
        getTrnReport(this);
      }
      // getTrnReport(this);
    },
    toggleValue(event) {
      this.selectTemplateReport = [];
      if (event.value.length !== 0) {
        this.selectTemplateReport.push(event.value[event.value.length - 1]);
      }
    },
    toggleValueType(event) {
      this.selectTypeReport = [];
      if (event.value.length !== 0) {
        this.selectTypeReport.push(event.value[event.value.length - 1]);
      }
    },
    // setHandlers(data) {
    //   let arr = [];

    //   if (data.items.length !== 0) {
    //     if (data.items[0] == 0) {
    //       arr = this.fullCompanyList.map((el) => el.handler_card);
    //     } else {
    //       data.items.forEach((element) => {
    //         arr.push(
    //           this.fullCompanyList.find((el) => el.id === element)[
    //             "handler_card"
    //           ]
    //         );
    //       });
    //     }

    //     arr = [...new Set(arr.flat())];
    //   } else {
    //     arr = store.state.company.curCompany
    //       ? store.state.company.curCompany["handler_card"]
    //       : null;
    //   }

    //   this.handlers = arr;
    // },
    // setBaseCode(data) {
    //   let arr = [];
    //   if (data.items.length !== 0) {
    //     if (data.items[0] == 0) {
    //       arr = this.fullCompanyList.map((el) => el.base_code);
    //     } else {
    //       data.items.forEach((element) => {
    //         if (
    //           this.fullCompanyList.find((el) => el.id === element)["base_code"]
    //         ) {
    //           arr.push({
    //             base_code: this.fullCompanyList.find((el) => el.id === element)[
    //               "base_code"
    //             ],
    //             base_name: this.fullCompanyList.find((el) => el.id === element)[
    //               "base_name"
    //             ],
    //           });
    //         }
    //       });
    //     }

    //     arr = [...new Set(arr)];
    //   } else {
    //     arr = store.state.company.curCompany
    //       ? {
    //           base_code: store.state.company.curCompany["base_code"],
    //           base_name: store.state.company.curCompany["base_name"],
    //         }
    //       : null;
    //   }

    //   this.baseCode = arr;
    // },
    // setCardType(data) {
    //   console.log(data);
    //   let arr = [];
    //   if (data.items.length !== 0) {
    //     if (data.items[0] == 0) {
    //       arr = this.fullCompanyList.map((el) => el.card_type);
    //     } else {
    //       data.items.forEach((element) => {
    //         if (
    //           this.fullCompanyList.find((el) => el.id === element)["card_type"]
    //         ) {
    //           arr.push(
    //             this.fullCompanyList.find((el) => el.id === element)[
    //               "card_type"
    //             ]
    //           );
    //         }
    //       });
    //     }

    //     arr = [...new Set(arr)];
    //   } else {
    //     arr = store.state.company.curCompany
    //       ? store.state.company.curCompany["card_type"]
    //       : null;
    //   }
    //   console.log(arr);
    //   this.cardType = arr.flat(1);
    // },
    // setHandlersInReports(data) {
    //   this.handlerReport = data;
    // },
    toggleValueColumn(event) {
      this.to = false;
      this.group_to = false;
      this.address = false;
      event.value.forEach((el) => {
        switch (el) {
          case "to":
            this.to = true;
            break;
          case "group_to":
            this.group_to = true;
            break;
          case "address":
            this.address = true;
            break;
        }
      });
    },
    toggleValueColumnTrn(event) {
      console.log(event.value.includes("handler"));
      let colArr = !store.state.curUser.hide_price
        ? this.columnsTrn
        : this.columnsTrnTO;
      colArr.forEach((el) => {
        this[el.value] = false;
      });
      event.value.forEach((el) => {
        this[el] = true;
      });
    },
    // changeTypeReport(e) {
    //   if (this.oneass) {

    //   }
    // }
    selectAllCards() {
      this.selectedCards = this.optionCardsList.map((el) => el.number);
    },
    getReportsToolsGlobal() {
      getCardsMini(this);
      getReportsTools(this);
    },
  },
  mounted() {
    this.selectedCompany = [];
    setTimeout(() => {
      // getCompany(this);
      this.getCompanyFromStore();
      this.getReportsToolsGlobal();
     
    }, 500);
  },
  created() {
    document.title = "Отчеты | " + window.appBaseTitle;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .p-dialog-mask.p-component-overlay.p-component-overlay-enter {
  z-index: 10004 !important;
}
.report-wrapper {
  ::v-deep .p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: transparent !important;
  }

  ::v-deep .p-inplace .p-inplace-display {
    padding-left: 0 !important;
  }
}
</style>