<template>
  <div class="flex flex-column company-select">
    <b v-if="showTitle" class="mb-2"> {{ title }} </b>
    <div class="w-full">
      <MultiSelect
        class="w-full company-select"
        :class="{ 'border-red-500': valueC.length == 0 }"
        v-model="valueC"
        :options="items"
        optionLabel="name"
        placeholder="Выбрать компанию"
        scrollHeight="40vh"
        optionValue="id"
        :filter="true"
        dataKey="id"
        :showToggleAll="false"
        emptyFilterMessage="Компания не найдена"
        emptyMessage="Список компаний не загружен"
        @change="selectingItems"
        @selectall-change="logger($event)"
        @hide="hideSelect"
        ref="select"
      >
        <template #footer>
          <div class="p-multiselect-footer bg-gray-300">
            <div
              class="flex align-items-center"
              style="padding: 0.75rem 1.25rem"
            >
              <Checkbox
                :binary="true"
                class="mr-2"
                @change="selectAll"
                v-model="selectall"
              />
              <span class="font-bold">Выбрать все</span>
            </div>
            <div
              class="flex align-items-center justify-content-between"
              style="padding: 0.75rem 1.25rem"
            >
              <Button
                @click="(valueC = []), (selectall = false), selectingItems()"
                class="p-button-sm p-button-text underline p-0 flex justify-content-center"
                label="Сбросить выбранные компании"
              />
              <Button
                @click="this.$refs.select.hide()"
                class="p-button-sm ml-2"
                label="Закрыть"
              />
            </div>
          </div>
        </template>
      </MultiSelect>
    </div>
  </div>
</template>
<script>
import store from "@/store";
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    showTitle: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    showToggleAll: {
      type: Boolean,
      required: true,
      default: true,
    },
    upadter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valueC: this.selectedItems,
      selectall: null,
      isFocus: true,
    };
  },
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
    companyList() {
      return store.state.company.curCompany;
    },
  },
  // mounted() {
  //   this.selectingItems();
  // },
  methods: {
    selectingItems() {
      this.$emit("upToParentItems", { items: this.valueC });
      // console.log(this.valueC.length);

      if (store.state.company.companyList.length == this.valueC.length) {
        this.$emit("upToParentItems", { items: [0] });
      } else {
        this.$emit("upToParentItems", { items: this.valueC });
      }
      // } else {
      //   this.$toast.add({
      //     severity: "error",
      //     summary: `Нет выбранных компаний`,
      //     detail: "Выберите минимум одну компанию",
      //     life: 5000,
      //   });
      // }
    },
    logger(e) {
      console.log(e.checked);
    },
    selectAll() {
      if (this.selectall) {
        this.valueC = this.items.map((el) => el.id);
      } else {
        this.valueC = [];
      }
      this.selectingItems();
    },
    hideSelect() {
      this.$emit("hide");
    },
  },
  watch: {
    curCompany(value) {
      value !== null ? (this.valueC = [value["id"]]) : null;
    },
    // selectall(value) {
    //   if (value) {
    //     this.valueC = this.items.map((el) => el.id);
    //   } else {
    //     this.valueC = [];
    //   }
    //   this.selectingItems();
    // },
    valueC(value) {
      if (value.length == this.items.length) {
        this.selectall = true;
      } else {
        this.selectall = false;
      }
    },
    upadter(val) {
      this.selectingItems();
    },
  },
  mounted() {
    if (this.selectedItems[0] == "0") {
      this.valueC[0] = store.state.company.curCompany.id;
      this.selectingItems();
    }
  },
};
</script>

<style lang="scss">
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.5rem 1.25rem !important;
}
</style>