<template>
  <div class="flex flex-column">
    <b v-if="showTitle" class="mb-2"> {{ title }} </b>
    <div class="w-full">
      <MultiSelect
        v-model="selectedHandler"
        :options="handlers"
        :filter="true"
        :showToggleAll="false"
        :placeholder="placeholder"
        class="w-full"
        :disabled="disabled"
        emptyFilterMessage="Совпадения не найдены"
        emptyMessage="Список обработчиков пуст"
        @change="toggleValue($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HandlerSelect",
  props: {
    showTitle: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    handlers: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedHandler: null,
    };
  },
  methods: {
    showCompany() {
      //   console.log(this.companys);
    },
    toggleValue(event) {
      this.selectedHandler = [];
      if (event.value.length !== 0) {
        this.selectedHandler.push(event.value[event.value.length - 1]);

        this.$emit("setHandler", this.selectedHandler[0]);
      } else {
        this.$emit("setHandler", "");
      }
    },
  },
  watch: {
    handlers() {
      this.selectedHandler = [];
    },
    selectedHandler(value) {
      if (!value.length) {
        this.$emit("setHandler", "");
      }
    },
  },
  mounted() {
    // this.selectCompanys.forEach((el) => {
    //   console.log(el);
    //   this.a = this.companys.find((elem) => elem.id === el);
    // });
  },
  updated() {
    // this.selectCompanys.forEach((el) => {
    //   console.log(el);
    //   this.a = this.companys.find((elem) => elem.id === el);
    // });
  },
};
</script>

<style>
</style>